<template>
  <span>
    <v-row>
      <span>
        <section class="flex">
          <!-- {{ formData }} -->
          <span v-if="formData.images">
            <div
              class="mother"
              v-for="(item, index) in formData.images"
              :key="index"
              sm="3"
              cols="3"
            >
              <v-img
                contain
                :src="item.base64"
                height="250px"
                width="125px"
                lazy-src="https://picsum.photos/id/11/100/60"
              ></v-img>
              <td width="5%" class="text-center">
                <button class="close-btn" @click="removeItem(item)">
                  X{{ item.index }}
                </button>
              </td>
              <v-switch v-model="is_video" label="Cadastrar Vídeo"></v-switch>
              <v-autocomplete
                v-model="item.color"
                :items="colorList()"
                item-text="name"
                item-value="id"
                label="Cor"
                color="amber darken-4"
                class="list-product"
                id="color"
              ></v-autocomplete>
              <v-autocomplete
                v-model="item.relevance"
                :items="relevance"
                item-text="value"
                item-value="value"
                label="Relevancia"
                color="amber darken-4"
                class="list-product"
                id="relevance"
              ></v-autocomplete>
              <span v-if="is_video">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="item.link_video" label="Link Vídeo">
                    </v-text-field>
                  </v-col>
                </v-row>
              </span>
            </div>
          </span>
        </section>
      </span>
    </v-row>
    <br />
    <image-uploader
      class="mb-4"
      :maxWidth="800"
      :maxHeight="1000"
      :quality="1.0"
      outputFormat="verbose"
      @input="setImage"
      @onUpload="setSpinnerLoadImage(true)"
      :preview="false"
      :capture="false"
      accept="video/*, image/*"
    ></image-uploader>
    <v-btn class="mr-4 register-btn" @click="saveContent()">Salvar</v-btn>
    <v-btn class="mr-4 register-btn" @click="setModal(false)">Cancelar</v-btn>
    <v-btn class="mr-4 register-btn" @click="clearForm()">Limpar</v-btn>
  </span>
</template>

<script>
import { rulesValidationFunction } from "@/utils/rulesFunctions";
import { mapState, mapActions } from "vuex";

export default {
  components: {},
  data: function () {
    return {
      is_video: false,
      relevance: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
        {
          value: 9,
        },
        {
          value: 10,
        },
        {
          value: 11,
        },
        {
          value: 12,
        },
        {
          value: 13,
        },
        {
          value: 14,
        },
        {
          value: 15,
        },
      ],
    };
  },
  computed: {
    ...mapState("ProductImage", ["formData"]),
    ...mapState("Utils", ["colors"]),
    ...mapState("Grid", ["list"]),
  },
  methods: {
    ...mapActions("ProductImage", [
      "setFormData",
      "save",
      "cleanItem",
      "cleanErrors",
      "show",
      "update",
      "setModal",
    ]),
    ...mapActions("ProductImage", ["save", "setModal"]),
    ...mapActions("Spinner", ["setModalSpiner"]),
    saveContent() {
      this.formData.product_id = this.$route.params.id;

      this.save(this.formData);
    },
    colorList() {
      let colors = this.list.map((a) => a.color);
      return colors;
    },
    removeItem(item) {
      let response = this.formData.images.filter((base64) => {
        return base64 !== item;
      });
      this.formData.images = response;
    },

    clearForm() {
      this.formData.images.map((item) => {
        this.removeItem(item);
        // item.color = ''
        // item.relevance = ''
      });
    },

    setSpinnerLoadImage(value) {
      this.setModalSpiner(value);
    },
    setImage: function (output) {
      // console.log(output);
      this.hasImage = true;
      let base64 = output.dataUrl;
      this.formData.images.push({ base64: base64 });
      this.setSpinnerLoadImage(false);
    },
    rulesFunction(name, lengthNeed) {
      return rulesValidationFunction(name, lengthNeed);
    },
  },
  // mounted() {
  //   if (this.$route.params.id) {
  //     this.show(this.$route.params.id);
  //   }
  // },
  destroyed() {
    this.clearForm();
    this.cleanItem();
    this.cleanErrors();
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  margin: 0 auto;
}
.flex > div {
  flex: 1 1 200px;
  margin: 10px;
  max-width: 25%;
}
.close-btn {
  position: absolute;
  top: 2px;
  font-size: 23px;
  right: 5px;
  background: rgba(255, 255, 255, 0.534);
  border-radius: 20px;
  color: rgb(20, 19, 19);
  height: 32px;
  width: 32px;
  z-index: 100;
}
.mother {
  position: relative;
}
</style>