<template>
  <div class="default-update-buy">
    <h2 class="text-center">Formulario Alteração Pedido</h2>
    <v-row>
      <v-col cols="4">
        <v-select
          label="Status Pedido"
          v-model="form.status"
          :items="buyStatusList"
          item-text="name"
          item-value="status"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.delivery_code"
          label="Codigo Rastreio"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="formData[0].payment_component == 1">
      <v-col cols="10">
        <p><strong>Pagamento</strong></p>
        <hr />
      </v-col>
      <v-col cols="3">
        <v-select
          label="Status Pagamento"
          v-model="form.payment_type"
          :items="paymentTypeList"
          item-text="name"
          item-value="status"
        ></v-select>
      </v-col>
      <v-col v-if="form.payment_type == 2" cols="4">
        <div style="margin-top: 23px !important">
          <span> Troco (R$) </span>
          <money
            class="money"
            v-model="form.transshipment"
            l
            v-bind="money"
          ></money>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-switch
          color="amber darken-4"
          label="Pago"
          v-model="form.payment_paid"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="right">
        <v-btn color="red" @click="setUpdateBuyModal(false)">Fechar</v-btn>

        <v-btn @click="updateBuy(form)" color="green" class="ml-5"
          >Salvar Alteração</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: function () {
    return {
      form: {
        delivery_code: null,
        status: null,
        payment_type: null,
        transshipment: null,
        buy_id: null,
      },
    };
  },
  props: {
    data: Array,
  },
  methods: {
    ...mapActions("DetailOrder", ["setUpdateBuyModal", "updateBuy"]),
  },
  computed: {
    ...mapState("DetailOrder", [
      "updateBuyModal",
      "buyStatusList",
      "paymentTypeList",
      "formData",
    ]),
  },
  mounted() {
    this.form.delivery_code = this.formData[0].delivery_code;
    this.form.status = parseFloat(this.formData[0].status);
    this.form.payment_type = this.formData[0].payment_type;
    this.form.transshipment = this.formData[0].transshipment;
    this.form.payment_paid = this.formData[0].paid;
    this.form.buy_id = this.$route.params.id;
  },
};
</script>

<style scoped>
.default-update-buy {
  padding: 30px !important;
}
</style>