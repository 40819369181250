// Prod
// export const baseApiUrl = 'https://webservice.granshopping.com.br/api';
// export const s3Url = 'https://granshopping.s3.amazonaws.com/products/';
// export const environment = 'production';
// export const myUrl = 'https://granshopping.com.br';

// Dev
// export const baseApiUrl = 'https://shoptest.gransys.com.br/api';
// export const s3Url = 'https://grancommtest.s3.amazonaws.com/products/';
// export const environment = 'dev';
// export const myUrl = 'http://localhost:8080';



// // Local
// export const baseApiUrl = 'http://127.0.0.1:8000/api';
// export const s3Url = "https://grancommtest.s3.amazonaws.com/products/";
//export const environment = 'dev';


// // DEV VICTOR
export const baseApiUrl = 'https://gransys-backend.finanto.io/api';
export const s3Url = "https://grancommtest.s3.amazonaws.com/products/";
export const environment = 'dev';


export const publicAppKey = "103432f1-7d42-419a-ac25-adebde2eb826"
// export const publicAppKey = "dc6c9f5d-c71f-4670-929e-b95896584420"
export const catchError = function (e) {
    alert(`Algo deu errado tente novamente!!! ... ${e}`)
};


export default { baseApiUrl, catchError };