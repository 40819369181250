export default {
    formData: {
    },
    list: [],
    errors: [],
    integrators: [
        { value: 'bling_token', name: 'Bling' },
        { value: 'global_stock_token', name: 'Outros' },
    ],
    linkApi: '',
    dialog: false
}